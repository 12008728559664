body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to bottom, #000000 5%, #010A10 30%, #59230B 75%, #2F1107 100%);
}
.default-background {
  background: linear-gradient(to bottom, #000000 5%, #010A10 30%, #59230B 75%, #2F1107 100%);
  height: 100vh;
  width: 100vw;
}

h1 {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 3.5em;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
}

h3 {
  margin-top: -3px;
  margin-bottom: 0;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
}

canvas {
  position: absolute;
}

.employment-field {
  pointer-events: none;
  margin: 10px 90px 0 50px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.64em;
  animation-duration: 5s;
  animation-name: fadeAndScale;
  animation-timing-function: cubic-bezier(.71,.55,.62,1.57);
}

.employment-field span {
  display: inline-block;
  opacity: 0;
  transform: translate(-150px, 0) scale(.3);
  animation: leftRight 4s forwards;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

@keyframes leftRight {
	40% {
		transform: translate(50px, 0) scale(.7);
		opacity: 1;
		color: #2c6934;
	}
	60% {
		color: #85aaac;
	}
	80% {
		transform: translate(0) scale(2);
		opacity: 0;
	}
	100% {
		transform: translate(0) scale(1);
		opacity: .6;
	}
}

@-webkit-keyframes leftRight {
	40% {
		transform: translate(50px, 0) scale(.7);
		opacity: 1;
		color: #2c6934;
	}
	60% {
		color: #161975;
	}
	80% {
		transform: translate(0) scale(2);
		opacity: 0;
	}
	100% {
		transform: translate(0) scale(1);
		opacity: .6;
	}
}

.employment-field span:nth-of-type(2) {
	animation-delay: .05s;
}
.employment-field span:nth-of-type(3) {
	animation-delay: .1s;
}
.employment-field span:nth-of-type(4) {
	animation-delay: .15s;
}
.employment-field span:nth-of-type(5) {
	animation-delay: .2s;
}
.employment-field span:nth-of-type(6) {
	animation-delay: .25s;
}
.employment-field span:nth-of-type(7) {
	animation-delay: .3s;
}
.employment-field span:nth-of-type(8) {
	animation-delay: .35s;
}
.employment-field span:nth-of-type(9) {
	animation-delay: .4s;
}
.employment-field span:nth-of-type(10) {
	animation-delay: .45s;
}
.employment-field span:nth-of-type(11) {
	animation-delay: .5s;
}
.employment-field span:nth-of-type(12) {
	animation-delay: .55s;
}
.employment-field span:nth-of-type(13) {
	animation-delay: .6s;
}
.employment-field span:nth-of-type(14) {
	animation-delay: .65s;
}

@keyframes fadeAndScale {
    from {
        opacity: 0;
        transform: scale(.1, .1);
    }
    to {
        opacity: 1;
        transform: scale(1, 1);
    }
}

@-webkit-keyframes fadeAndScale {
    from {
        opacity: 0;
        transform: scale(.1, .1);
    }
    to {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.header-container {
    height: 100vh;
}

#header {
  pointer-events: none;
  position: relative;
  height: 100vh;
  width: 490px;
  margin: 28vh 59vw 400px auto;
}

#scroll-btn {
  pointer-events: visibleFill;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 267px 20px auto;
  border: none;
  cursor: pointer;
  background-color: transparent;
  opacity: .3;
  background: url('./images/arrow-white.png');
  background-size: cover;
  outline: none;
  -webkit-filter: drop-shadow(0px 0px 0px rgba(255,255,255,0.80));
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  animation: fadeAndScaleBtn 14s forwards;
}

@keyframes fadeAndScaleBtn {
    from {
        opacity: 0;
        transform: scale(0, 0);
    }
    to {
        opacity: .4;
        transform: scale(1, 1);
    }
}

@-webkit-keyframes fadeAndScaleBtn {
    from {
        opacity: 0;
        transform: scale(0, 0);
    }
    to {
        opacity: .4;
        transform: scale(1, 1);
    }
}

#scroll-btn:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  opacity: .9;
  filter: drop-shadow(0px 0px 8px #ffffff);
	-webkit-filter: drop-shadow(0px 0px 8px #ffffff);
}

#name {
  pointer-events: none;
  z-index: 1;
  padding: 25px 60px 25px 25px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: none;
  color: #ededed;
}

#name h1 {
  color: #c1c3c1;
  text-shadow: 1px 1px #161975,
               2px 2px #2c6934,
               3px 3px #804b33,
               4px 4px #793718,
               5px 5px #4e654f;
  transition: 3s all linear;
  text-transform: uppercase;
  -webkit-animation-name: name; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 10s; /* Safari 4.0 - 8.0 */
  animation-name: name;
  animation-duration: 10s;
}

@keyframes name {
  0%   {
    color: #ededed;
    text-shadow:
      1px 1px #161975, 2px 2px #161975,
      3px 3px #2c6934, 4px 4px #2c6934,
      5px 5px #804b33, 6px 6px #804b33,
      7px 7px #793718, 8px 8px #793718,
      9px 9px #4e654f, 10px 10px #4e654f;
  }
  50% {
    color: #121312;
    text-shadow:
      1px 1px #161975,
      2px 2px #2c6934,
      3px 3px #804b33,
      4px 4px #793718,
      5px 5px #4e654f;
  }
  100% {
    color: #c1c3c1;
    text-shadow:
      1px 1px #161975,
      2px 2px #2c6934,
      3px 3px #804b33,
      4px 4px #793718,
      5px 5px #4e654f;
  }
}

@-webkit-keyframes name {
  0%   {
    color: #ededed;
    text-shadow:
      1px 1px #161975, 2px 2px #161975,
      3px 3px #2c6934, 4px 4px #2c6934,
      5px 5px #804b33, 6px 6px #804b33,
      7px 7px #793718, 8px 8px #793718,
      9px 9px #4e654f, 10px 10px #4e654f;
  }
  100% {
    color: #121312;
    text-shadow:
      1px 1px #161975,
      2px 2px #2c6934,
      3px 3px #804b33,
      4px 4px #793718,
      5px 5px #4e654f;
  }
}

#period {
  text-shadow: none;
  margin: 0 -1px 0 5px;
  -webkit-animation: color-change 2s infinite;
  -moz-animation: color-change 2s infinite;
  -o-animation: color-change 2s infinite;
  -ms-animation: color-change 2s infinite;
  animation: color-change 2s infinite;
}

@-webkit-keyframes color-change {
  0% { color: #59230B; }
  50% { color: #8e222b; }
  100% { color: #59230B; }
}

@-moz-keyframes color-change {
  0% { color: #59230B; }
  50% { color: #8e222b; }
  100% { color: #59230B; }
}

@-ms-keyframes color-change {
  0% { color: #59230B; }
  50% { color: #8e222b; }
  100% { color: #59230B; }
}

@-o-keyframes color-change {
  0% { color: #59230B; }
  50% { color: #8e222b; }
  100% { color: #59230B; }
}

@keyframes color-change {
  0% { color: #59230B; }
  50% { color: #8e222b; }
  100% { color: #59230B; }
}

.App {
  color: #18261d;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 200px auto auto;
  position: relative;
  overflow-x: hidden;
}

.App .landing-container {
  width: 115vw;
  position: absolute;
  bottom: -20px;
  z-index: 0;
  overflow: hidden;
}
.App .landing-container .landing-trees {
  width: 112vw;
  overflow: hidden;
  height: 110%;
  bottom: 0px;
}

.link-container {
  display: flex;
  justify-content: space-around;
}

.link-container a img {
  margin-top: 20px;
  width: 95px;
  height: 50px;
  border-radius: 10px;
  border: 4px solid #000;
}

.link-container a img:hover {
  border: 4px solid #fff;
  transition: all .5s;
  transform: scale(.95);
}

#gradient {
  height: 100vh;
  width: 100vw;
}

.ReactModal__Overlay--after-open {
  z-index: 2;
}

@media (max-width: 830px) {
  #header {
    margin: 35vh 34vw 278px auto;
  }
}

@media (max-width: 600px) {
  #header {
    margin: 20vh auto 418px auto;
  }

  #title {
    margin: 0;
    text-align: center;
  }

  #scroll-btn {
    margin: 20px auto 0 auto;
  }

  #name {
    padding: 25px;
  }
}

@media (max-width: 490px) {
  #name {
    padding: 0;
    text-align: center;
  }

  #header {
    width: 100%;
    margin-bottom: 492px;
  }
}
@media (max-width: 430px) {
  h1 {
    font-size: 2.4em;
  }

  #title {
    font-size: 1.4em;
  }
}
@media (max-width: 380px) {
  h1 {
    font-size: 2.2em;
  }

  #title {
    font-size: 1.4em;
  }

  #header {
    margin-top: 8vh;
    margin-bottom: 84vh;
  }

  .App {
    height: 100vh;
    position: relative;
  }

  #scroll-btn {
    position: absolute;
    bottom: -50px;
    left: 42%;
  }
}
