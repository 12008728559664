.project-modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    color: #19281c;
    background: #bdbdbd;
    overflow: auto;
    outline: none;
    border-radius: 4px;
    border: 2px solid #18261D;
    margin: 0 auto;
    padding: 25px;
    max-width: 900px;
    overflow: scroll;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.project-details {
    overflow: auto;
    height: 100%;
}

.project-detail-header {
    font-size: 1.8em;
    font-weight: 700;
    text-align: center;
}

.project-detail-image {
    display: block;
    margin: 30px auto;
    border: 1px solid #18261D;
    border-radius: 6px;
    width: 100%;
    max-width: 630px;
}

.home-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    border-radius: 11px;
    color: white;
    background: grey;
    cursor: pointer;
}

.home-button:hover {
    color: #E91326;
}

.resume-modal h3 {
    text-align: center;
}

.resume-download {
    text-decoration: none;
    color: #71320b;
    margin-right: 5px;
    cursor: pointer;
}
