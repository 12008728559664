#projects {
    text-align: center;
    background: #14212b;
    padding-bottom: 40px;
    width: 100%;
}

#projects h2 {
    color: #989191;
    text-align: center;
    font-weight: 700;
    font-size: 1.8em;
    margin: 25px 0 25px;
    width: 100%;
}

.projects-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
    max-width: 1200px;
}

.each-project {
    position: relative;
    border: 2px solid #18261d;
    overflow: hidden;
    margin: 10px;
    max-width: 47%;
    min-width: 400px;
    max-height: 400px;
    position: relative;
    overflow: hidden;
    margin: 10px;
    max-width: 47%;
    min-width: 400px;
    max-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.each-project .project-image {
    display: block;
    width: 100%;
}

.each-project:hover .project-image {
    transition: all 0.5s;
    transform: scale(1.3);
}

.article-layer {
    background: rgb(206, 206, 206);
    -webkit-transition: opacity 1000ms;
    transition: opacity 1000ms;
    opacity: 0;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.article-layer:hover {
    opacity: 0.9;
}

.article-layer h3 {
    padding: 25px 0 15px 0;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2em;
    font-weight: 700;
}

.article-layer p {
    margin: 0 auto;
    text-align: left;
    width: 90%;
}

.article-layer .project-icons {
    padding: 20px 50px;
    width: 60px;
}

.article-layer .project-button {
    position: relative;
    top: 30px;
    height: 35px;
    width: 100px;
    border: none;
    border-radius: 5px;
    background: #000;
    color: #fff;
    cursor: pointer;
    outline: none;
    margin: 0 auto;
}

@media (max-width: 847px) {
    .each-project {
        min-width: 90%;
        max-height: 430px;
    }
}

@media (max-width: 460px) {
    .article-layer h3 {
        margin-bottom: 0;
        font-size: 1.8em;
        padding-bottom: 5px;
    }
}

@media (max-width: 375px) {
    .each-project {
        max-height: 190px;
    }

    .article-layer h3 {
        padding-top: 10px;
        font-size: 1.65em;
    }

    .article-layer .project-icons {
        padding: 10px 30px;
        width: 50px;
    }

    scroll-btn {
        bottom: 0;
        position: absolute;
        right: 43%;
    }
}

.react-3d-carousel {
    width: 400px;
    height: 300px;
    position: relative;
    perspective: 1000px;
    margin: auto;
}

.react-3d-carousel .carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
}

.react-3d-carousel .carousel figure {
    display: block;
    position: absolute;
    width: 400px;
    height: 300px;
    left: 10px;
    top: 10px;
    border: 2px solid black;
    line-height: 116px;
    font-size: 80px;
    font-weight: bold;
    color: white;
    text-align: center;
}

.react-3d-carousel .carousel figure {
    background: hsla(160, 100%, 50%, 0.8);
}

#content table {
    position: absolute;
    top: 400px;
    width: 400px;
}

.react-3d-carousel .prev {
    position: absolute;
    height: 100%;
    width: 15%;
    top: 0px;
    left: 0px;
}

.react-3d-carousel .prev {
    background-color: transparent;
    background-size: cover;
    display: inline-block;
    background: url('../../styles/images/chevron-pointing-to-the-left.png');
    position: absolute;
    top: 166px;
    bottom: 0;
    right: 250px;
    left: 0;
    width: 52px;
    height: 64px;
    margin: auto;
}

.react-3d-carousel .next {
    position: absolute;
    height: 100%;
    width: 15%;
    top: 0px;
    right: -5px;
}

.react-3d-carousel .next {
    background-color: transparent;
    background-size: cover;
    display: inline-block;
    background: url('../../styles/images/chevron-pointing-to-the-left.png');
    transform: rotate(180deg);
    position: absolute;
    top: 166px;
    bottom: 0;
    left: 400px;
    right: 0;
    width: 52px;
    height: 64px;
    margin: auto;
}
